import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Главная | Час-Пик Агентство недвижимости
			</title>
			<meta name={"description"} content={"Дом вашей мечты ждет"} />
			<meta property={"og:title"} content={"Главная | Час-Пик Агентство недвижимости"} />
			<meta property={"og:description"} content={"Дом вашей мечты ждет"} />
			<meta property={"og:image"} content={"https://quantixarix.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://quantixarix.com/img/942089.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://quantixarix.com/img/942089.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://quantixarix.com/img/942089.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://quantixarix.com/img/942089.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://quantixarix.com/img/942089.png"} />
			<meta name={"msapplication-TileImage"} content={"https://quantixarix.com/img/942089.png"} />
			<meta name={"msapplication-TileColor"} content={"https://quantixarix.com/img/942089.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0px 0 0px 0" background="--color-primary" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Час-Пик
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					Добро пожаловать в Час-Пик Агентство недвижимости, где дом вашей мечты становится реальностью. Мы поможем вам найти идеальную недвижимость, соответствующую вашему образу жизни и потребностям. Наша миссия - обеспечить непревзойденный сервис с профессионализмом и честностью.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contact-us"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="--color-secondary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						sm-width="100%"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Контакты
					</Link>
					<Link
						href="/services"
						padding="10px 24px 10px 24px"
						color="--secondary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						border-width="2px"
						border-style="solid"
						border-color="--color-secondary"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						lg-margin="0px 0px 0px 0px"
						sm-width="100%"
						hover-border-color="--color-orange"
						hover-color="--orange"
						hover-transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
						transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
					>
						Услуги
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://quantixarix.com/img/1.jpg"
					width="555px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 33px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 100px 0px 100px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="#ffffff"
					font="--headline2"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Почему выбирают нас?
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="stretch"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				md-grid-template-columns="1fr"
				justify-items="center"
			>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 56px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 56px 35px"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
					Экспертиза: 
					</Text>
					<Text margin="0px" color="--dark" text-align="center" font="--lead">
					Многолетний опыт работы в отрасли гарантирует, что вы получите лучшие советы и услуги.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 56px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 56px 35px"
					position="relative"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
					Индивидуальный подход: 
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--dark"
						text-align="center"
						font="--lead"
						flex="1 1 auto"
					>
						Мы подбираем наши услуги в соответствии с вашими конкретными требованиями.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 56px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 56px 35px"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
					Разнообразная недвижимость: 
					</Text>
					<Text
						margin="0px 0px 48px 0px"
						color="--dark"
						text-align="center"
						font="--lead"
						flex="1 1 auto"
					>
						Мы предлагаем широкий спектр недвижимости - от городских квартир до роскошных особняков.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section text-align="center" color="--light" padding="80px 0" sm-padding="40px 0">
			<Text
				as="h1"
				font="--headline1"
				color="--primary"
				md-font="--headline2"
				margin="10px 0 0 0"
			>
				О нас
			</Text>
			<Text
				as="p"
				font="--lead"
				color="--dark"
				margin="40px 0 20px 0"
				text-align="left"
			>
				Поиск подходящего дома - это опыт, меняющий жизнь. Наша опытная команда стремится сделать этот путь легким и приятным. Имея разнообразный портфель недвижимости, мы удовлетворяем самые разные вкусы и бюджеты.
				<br />
				<br />
Мы гордимся своим знанием рынка и проницательными советами. Покупаете ли вы, продаете или арендуете недвижимость, Час-Пик Агентство недвижимости - ваш надежный партнер в сфере недвижимости.

			</Text>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="center"
					md-text-align="center"
					sm-text-align="left"
				>
					Дом вашей мечты всего в одном звонке
				</Text>
				<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Компания Час-Пик Агентство недвижимости стремится воплотить ваши мечты о недвижимости в реальность. Свяжитесь с нами сегодня, и давайте вместе начнем это увлекательное путешествие.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});